const {
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_REALM
} = process.env;

export const keycloakConfig = {
  url: REACT_APP_KEYCLOAK_URL,
  realm: REACT_APP_KEYCLOAK_REALM,
  clientId: REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const keycloakProviderConfig = {
    onLoad: 'login-required',
  checkLoginIframe: false
}
