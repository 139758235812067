import {useRecordContext, useTranslate} from "ra-core";
import {useNotify, useRefresh} from "react-admin";
import {dataProvider} from "../../../../providers/DataProvider";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import * as React from "react";


const DeleteDialog = (props) => {
    const {resource, open, closeDialog} = props;
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();


    const handleDelete = () => {
        const promise = record => dataProvider.delete(resource, {id: record.code});
        return new Promise(resolve => {
            promise(record).then(response => {
                refresh();
                notify(`resources.${resource}.notifications.delete.success`, {type: 'success'})
            }).catch(error => {
                notify(`resources.${resource}.notifications.delete.failure`, {type: 'error'})
            })
        })
    }

    return (
            <Dialog open={open}>
                <DialogTitle id="delete-dialog-title" style={{color: 'black'}}>
                    {translate(`ra.message.delete_title`, {name: translate(`resources.${resource}.name`, {smart_count: 1}), id: record.id})}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('ra.message.delete_content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeDialog}>
                        {translate('ra.action.cancel')}
                    </Button>
                    <Button onClick={handleDelete} autoFocus>
                        {translate('ra.boolean.true')}
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default DeleteDialog;