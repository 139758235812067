import {keycloak} from "../keycloak";

export const onTokenExpired = () => {
  keycloak
    .updateToken(30)
    .then(() => {
      console.log("successfully get a new token", keycloak.token);
    })
    .catch(() => {
      console.error("failed to refresh token");
    });
};

export const onAuthSuccess = () => {}
export const onAuthError = (error) => {}
export const onAuthRefreshSuccess = () => {}
export const onAuthRefreshError = () => {}
export const onAuthLogout = () => {}
export const onEvent = (event, error) => {}
export const onReady = (authenticated) => {}
export const onToken = () => {}
export const hasRole = (role, token, type="api") => {
   return token?.[type].roles?.includes(role);
}
