import {ChipField, useRecordContext} from "react-admin";
import {useTranslate} from "ra-core";


export const StatusField = ({resource, source}) => {
    const translate = useTranslate();
    const record = useRecordContext();

    record.translatedStatus = translate(`resources.status.${record[source]}`);


    return <ChipField source={'translatedStatus'}/>
}
