import React from 'react';
import Dialog from '@mui/material/Dialog';
import {dataProvider} from "../../../../providers/DataProvider";
import {useTranslate} from "ra-core";
import {useRecordContext} from "react-admin";
import {PaymentFields} from "../../../resources/Payment/PaymentList/FormFields/Fields";


const EditDialog = (props) => {
    const [loading, setLoading] = React.useState(false);
    const record = useRecordContext();
    const {resource, onChange, open, closeDialog, shouldBackdropClickClose} = props;
    const translate = useTranslate();

    const backdropClickCheck = (cause) => {
        return (cause !== 'backdropClick')
    }

    const promise = (data) => dataProvider.update(resource, {data: data, id: record.id});

    const onClose = (event, cause) => {
        if(shouldBackdropClickClose){
            closeDialog();
        }else{
            if(backdropClickCheck(cause))
                closeDialog();
        }
    }


    const getFields = (resource) => {
        const defaultProps = {
            handleCloseClick: closeDialog,
            onSubmitPromise: promise,
            onChange: onChange,
            loading: loading,
            setLoading: setLoading,
            closeDialog: closeDialog,
            resource: resource,
            action: 'edit',
        }
        const fields = {
            payments: <PaymentFields {...defaultProps} />,
            // codes: <CodeFields {...defaultProps} />
        }
        return fields[resource] ?? '<span>no fields given</span>';
    };


    return (
            <Dialog
                fullWidth
                open={open}
                onClose={(e, c) => onClose(e,c)}
                aria-label={"Edit "+resource}
            >
                {getFields(resource)}
            </Dialog>
    );
}

export default EditDialog;
