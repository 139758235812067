import React from 'react';
import {Button, useRefresh,} from 'react-admin';
import IconContentAdd from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {dataProvider} from "../../../../providers/DataProvider";
import {useTranslate} from "ra-core";
import {PaymentFields} from "../../../resources/Payment/PaymentList/FormFields/Fields";

const ModalCreateButton = (props) => {
    const [loading, setLoading] = React.useState(false);
    const {resource, open, setOpen, shouldBackdropClickClose, disabled} = props;
    const refresh = useRefresh();
    const translate = useTranslate();

    const backdropClickCheck = (cause) => {
        return (cause !== 'backdropClick')
    }


    const onChange = () => {
        refresh();
    }


    const handleClick = () => {
        setOpen(true);
    };

    const handleCloseClick = (event, cause) => {
        if(shouldBackdropClickClose){
            setOpen(false);
        }else{
            if(backdropClickCheck(cause))
                setOpen(false);
        }
    }

    const promise = (data) => dataProvider.create(resource, {data: data});

    const getFields = (resource) => {
        const defaultProps = {
            resource: resource,
            handleCloseClick: handleCloseClick,
            onSubmitPromise: promise,
            onChange: onChange,
            loading: loading,
            setLoading: setLoading,
            closeDialog: handleCloseClick,
        }

        const fields = {
            payments: <PaymentFields {...defaultProps} />,
            // codes: <CodeFields {...defaultProps} />
        }

        if(typeof fields[resource] === 'function'){
            fields[resource]();
        }

        return fields[resource] ?? '<span></span>';
    };


    return (
        <>
            <Button onClick={handleClick} disabled={disabled} label="ra.action.create">
                <IconContentAdd/>
            </Button>
            <Dialog
                fullWidth
                open={open}
                onClose={(event, cause) => handleCloseClick(event, cause)}
                aria-label={"Create " + resource}
            >
                {getFields(resource)}
            </Dialog>
        </>
    );
}


export default ModalCreateButton;
