import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useGetIdentity, UserMenu, SidebarToggleButton, useAuthState} from 'react-admin';
import {Breadcrumbs, Link} from "@mui/material";
import {MenuItem} from "@mui/material";



export const CustomAppBar = (props) => {
    const { identity } = useGetIdentity();
    const {authenticated} = useAuthState();
    return (
        <AppBar {...props} title={process.env.REACT_APP_NAME}>
            <Toolbar className={'topbar-container'}>
                <div className={"topbar-left"}>
                    <div className={"container"}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                <span>Dashboard</span>
                            </Link>
                            <span className={"current-section"}>    </span>
                        </Breadcrumbs>
                        <Typography variant="h6" id="react-admin-title"/>
                    </div>
                    <SidebarToggleButton/>
                </div>
                <div className={"topbar-right"}>
                    {authenticated !== undefined && <UserMenu {...props} label={identity && identity.fullName}>
                        <MenuItem>{identity && identity.fullName}</MenuItem>
                    </UserMenu>}
                </div>

            </Toolbar>
        </AppBar>
    )
};

