import {keycloak} from "../../../auth/keycloak/keycloak";

const authItem = 'auth';
const userItem = 'user';
const expirationTime = 'expires_in';
const issuedAt = 'obtained_at';

const removeDataFromLocalStorage = () => {
    localStorage.removeItem(authItem);
    localStorage.removeItem(userItem);
    localStorage.removeItem(expirationTime);
    localStorage.removeItem(issuedAt);
}


const makeRequest = (endpoint, body = null) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    })

    if(localStorage.getItem(authItem))
        headers.append('Authorization', `Bearer ${localStorage.getItem(authItem)}`);

    const request = new Request(`${process.env.REACT_APP_DATA_PROVIDER_API_URL}/${endpoint}`, {
        method: 'POST',
        body: body,
        headers: headers
    });

    return request;
}

const SanctumAuthProvider = {
    /*refreshToken: () => {
        const request = makeRequest('refresh-token');
        return fetch(request).then(response => {
            if(response.status < 200 || response.status >= 300){
                throw new Error(response.statusText);
            }
            return response.json();
        }).then( auth => {
            removeDataFromLocalStorage();
            localStorage.setItem(authItem, auth.access_token);
            localStorage.setItem(expirationTime, auth.expires_in);
            localStorage.setItem(issuedAt, auth.obtained_at);
            localStorage.setItem(userItem, JSON.stringify(auth.user));
        }).catch((e) => {
            console.log('response::', e)
            throw new Error(e);
        })
        return Promise.resolve();
    },*/
    login: ({ username, password }) => {
        const request = makeRequest('login', JSON.stringify({username, password}));
        return fetch(request).then(response => {
            if(response.status < 200 || response.status >= 300){
                throw new Error(response.statusText);
            }
            return response.json();
        }).then( auth => {
            localStorage.setItem(authItem, auth.access_token);
            localStorage.setItem(expirationTime, auth.expires_in);
            localStorage.setItem(issuedAt, auth.obtained_at);
            localStorage.setItem(userItem, JSON.stringify(auth.user));
        }).catch((e, t) => {
            throw new Error(e);
        })
    },
    getToken: () => {
        return localStorage.getItem(authItem);
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            SanctumAuthProvider.logout().then(() => Promise.reject())
        }
        return Promise.resolve();
    },
    // when the user navigates, make sure that their credentials are still valid
    checkAuth: () => {
        if(localStorage.getItem(authItem) && localStorage.getItem(expirationTime)) {
            const now = (Math.floor(Date.now() / 1000));
            if (now - localStorage.getItem(issuedAt) > (localStorage.getItem(expirationTime) * 60)) {
                removeDataFromLocalStorage();
                return Promise.reject({message: false});
            }
            return Promise.resolve( localStorage.getItem(authItem));
        }
        return Promise.reject({message: false});

    },
    /*checkValidityToken: () => {
        if(localStorage.getItem(authItem) && localStorage.getItem(expirationTime)){
            const now = (Math.floor(Date.now() / 1000));
            if(now - localStorage.getItem(issuedAt) > (localStorage.getItem(expirationTime) * 60)){
                SanctumAuthProvider.refreshToken().then(() => {
                    return Promise.resolve();
                })
            }
            return Promise.reject();
        }
        return Promise.reject();
    },*/
    logout: () => {
        const request = makeRequest('logout');
        return fetch(request).then(response => {
            if(response.status < 200 || response.status >= 300){
                throw new Error(response.statusText);
            }
            return response.json();
        }).then( _ => {
            removeDataFromLocalStorage();
        }).catch((e) => {
            throw new Error(e);
        })
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => {
        try {
            const { id, name:fullName, avatar } = JSON.parse(localStorage.getItem(userItem));
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // get the user permissions (optional)
    getPermissions: () => {
        return Promise.resolve()
    },
};

export default SanctumAuthProvider;