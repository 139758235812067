import {useRecordContext} from "ra-core";


export const AmountField = (props) => {
    const record = useRecordContext();

    const value = new Intl.NumberFormat('it-IT', {
        currency: 'EUR',
        style: 'currency'
    }).format(record.amount / 100);

    return (
        <span>{value}</span>
    )


}
