import {useRecordContext, useTranslate} from "ra-core";
import {MenuItem} from "@mui/material";
import * as React from "react";
import {dataProvider} from "../../../../providers/DataProvider";
import {useNotify, useRefresh} from "react-admin";

export const DuplicateTransactionButton = (props) => {
    const {resource} = props;
    const record = useRecordContext();
    const [loading, setLoading] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const translate = useTranslate();

    const handleDuplicate = () => {
        let data = {amount: record.amount, description: record.description+"_copia", long_description: record.longDescription, notes: record.notes};
        setLoading(true);
        return new Promise(resolve => {
            dataProvider.create(resource,{data:data}).then((result) => {
                setLoading(false);
                notify(`resources.${resource}.notifications.duplicate.success`, {type: 'success'})
                refresh();
            }).catch(error => {
                const renderableError = error.body.data.description !== null && typeof error.body.data.description === 'object';
                if(renderableError) {
                    const errors = error.body.data.description;
                    let errorString = '';
                    for(let key in errors){
                        errorString += errors[key][0] + "\n";
                    }
                    notify(errorString, {type:'error', multiLine: true})
                }else{
                    notify(error.body.data.message, {type:'error', multiLine: true})
                }
                setLoading(false);
            });
        })


    }

    return (
        <MenuItem onClick={handleDuplicate} disabled={props.disabled || loading} className={props?.className}>{translate('resources.payments.actions.duplicate')}</MenuItem>
    )
}
