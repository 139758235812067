import {fetchUtils, HttpError} from 'react-admin';
import {stringify} from 'query-string';
import {keycloak} from "../../auth/keycloak/keycloak";
import SanctumAuthProvider from '../auth/SanctumAuthProvider/SanctumAuthProvider'

const httpClient = fetchUtils.fetchJson;
const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;

const getLocale = () => {
    return localStorage.getItem('locale') ?? 'it'
}

const attachParams = (filters) => {
    let query = {};

    for (const key in filters)
        query["filter[" + key + "]"] = filters[key].toString();

    return query;
}

const getHeaders = () => {
    let headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept-Language': getLocale()
    });


    if (process.env.REACT_APP_AUTH_PROVIDER === "keycloak") {
        if (keycloak.authenticated) {
            headers.set('Authorization', 'Bearer ' + keycloak.token);
        }
    } else {
        headers.set('Authorization', 'Bearer ' + SanctumAuthProvider.getToken());
    }

    return headers;
}

export const dataProvider = {
    getChoices: (url) => {
        url = url.startsWith('http') ? url : apiUrl + url;
        return httpClient(url, {headers: getHeaders()}).then(({json}) => ({
            data: json.data,
            total: json.data.length,
        })).catch((err) => {
            throw new HttpError('error', err.status, err.body)
        });
    },
    getFormattedSelectOptions: (resource, params) => {
        const query = {
            ...attachParams(params?.filters)
        }
        const url = `${apiUrl}/${resource}?format=select-options&${stringify(query)}`;
        return httpClient(url, {
            headers: getHeaders()
        }).then(({json}) => ({
            data: json.data,
            total: json.meta?.total ?? ''
        })).catch((err) => {
            throw new HttpError('error', err.status, err.body)
        });
    },
    getExportableFields: (resource) => {
        const url = `${apiUrl}/${resource}/exportable-fields`;
        return httpClient(url, {
            headers: getHeaders()
        }).then(({headers, json}) => ({
            data: json.data,
            total: json.meta?.total ?? ''
        })).catch(error => {
            throw new Error('Error while')
            // throw new HttpError('error', error.status, error.body)
        });
    },
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: `${order === 'ASC' ? "" : "-"}${field}`,
            "page[size]": perPage,
            "page[number]": page,
            format: params.filter.format,
            ...attachParams(params.filter)
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {
            headers: getHeaders()
        }).then(({json}) => ({
            data: json.data,
            pageInfo: {
                hasNextPage: json.links.next,
                hasPreviousPage: json.links.prev,
            },

        })).catch(error => {
            throw new HttpError(`ra.notification.http_error`, error.status, error.body)
        });
    },
    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json.data,
        })),
    getAll: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {headers: getHeaders()})
            .then(({json}) => ({
                data: json.data,
            }))
            .catch(error => {
                throw new HttpError('error', error.status, error.body)
            });
    },
    getDetails: (resource, params) => {
        const includes = params.include ? `?include=${params.include}` : ``;
        const format = params.format ? `?format=${params.format}` : ``;
        return httpClient(`${apiUrl}/${resource}/${params.id}${includes}${format}`, {headers: getHeaders()})
            .then(({json}) => ({
                data: json.data,
            }))
            .catch(error => {
                throw new HttpError('error', error.status, error.body)
            });
    },
    getMany: (resource, params) => {
        const query = {
            "filter[id]": params.ids ? params.ids.join(',') : "",
            "page[size]": 100
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {headers: getHeaders()}).then(({json}) => ({data: json.data}));
    },
    getExportData: (resource, fields, params) => {
        const query = {
            ...attachParams(params.filterValues)
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}&format=export&fields=${fields.fields.join(',')}`;
        return httpClient(url, {
            headers: getHeaders()
        }).then(({headers, json}) => ({
            data: json.data,
        }));
    },
    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url, {headers: getHeaders()}).then(({headers, json}) => ({
            data: json.data,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: getHeaders()
        }).then(({json}) => ({data: json})),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: getHeaders()
        }).then(({json}) => ({data: json.data}));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers: getHeaders()
        })
            .then(({json}) => ({data: json}))
            .catch((err) => {
                throw new HttpError('error', err.status, err.body)
            }),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(({json}) => ({data: json})),

    deleteMany: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}?ids=${params.ids.join(',')}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
            headers: getHeaders()
        }).then(({json}) => ({data: [json]}));
    },
    removeOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}/${params.reference}/${params.id}`, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(({json}) => ({data: json.message}))
    }
}
