import DialogContent from "@mui/material/DialogContent";
import React, {Fragment, useEffect} from "react";
import {useTranslate} from "ra-core";
import {useRecordContext} from "react-admin";
import {Labeled} from "react-admin";
import {AmountField} from "../../../../ui/fields/AmountField";
import {DateField} from "../../../../ui/fields/DateField";
import {dataProvider} from "../../../../../providers/DataProvider";
import {FormField} from "../../../../ui/fields/FormField/FormField";

export const PaymentShowFields = props => {
    const record = useRecordContext();
    const {resource} = props;
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const translate = useTranslate();

    useEffect(() => {
        dataProvider.getDetails(resource, {id: record.id}).then((response) => {
            setData(response.data)
            setLoading(false)
        })
    }, [resource, record.id])

    const dataFields = [
        {type: 'text', title:translate('resources.payments.fields.code'), name:'code', value: data?.code.toString()},
        {type: 'text', title:translate('resources.payments.fields.transaction_id'), name:'transaction_id', value: data?.transaction_id.toString()},
        {type: 'text', title:translate('resources.payments.fields.description'), name:'description', value: data?.description.toString()},
        {type: 'text', title:translate('resources.payments.fields.longDescription'), name:'longDescription', value: data?.longDescription.toString()},
        {type: 'text', title:translate('resources.payments.fields.notes'), name:'notes', value: data?.notes.toString()},
        {type: 'text', title:translate('resources.payments.fields.created_by'), name:'created_by', value: data?.created_by.toString()},
        {type: 'currency', title:translate('resources.payments.fields.amount'), name:'amount', value:data?.amount.toString()},

    ];

    return (
        <Fragment>
            <DialogContent>
                {!loading && <div>
                    {dataFields.map((field, index) => {
                            return <FormField type={field.type}
                                              value={field.value}
                                              name={field.name}
                                              title={field.title}
                                              key={`${field.name}_${index}`}
                            />
                        }
                    )}
                </div>}
            </DialogContent>
        </Fragment>
    );

/*  <div><Labeled label={translate('resources.payments.fields.status')}>
                    <p> {record?.status}</p>
                </Labeled></div>
                <div><Labeled label={translate('resources.payments.fields.amount')}>
                    <p> <AmountField source={'amount'} /></p>
                </Labeled></div>
                <div><Labeled label={translate('resources.payments.fields.updated_at')}>
                    <p> <DateField value={record?.updated_at} record={record} /></p>
                </Labeled></div>
            </DialogContent>
        </Fragment>*/

}
