import {TextField, useLocale, useRecordContext} from 'react-admin';
import {Box, Tooltip} from "@mui/material";

export const DateField = ({source, value}) => {
    const record = useRecordContext();
    const date = value || record[source];
    const locale = useLocale();
    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })

    const units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365 / 12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
    }

    const getRelativeTime = (d1, d2 = new Date()) => {
        let elapsed = d1 - d2

        for (let u in units)
            if (Math.abs(elapsed) > units[u] || u === 'second')
                return rtf.format(Math.round(elapsed/units[u]), u)
    }

    const formatTimeAgo = (date) => {
        return getRelativeTime((new Date(date)));
    }

    record.formattedUpdatedAt = formatTimeAgo(date);
    return <Tooltip title={date} placement={"right"} arrow>
        <Box component="span">
            <TextField source={'formattedUpdatedAt'} />
            </Box>
    </Tooltip>
}
