import {
    required,
    TextInput, useDataProvider,
    useListContext,
    useNotify,
    SimpleForm, Toolbar, SaveButton, Button
} from "react-admin";
import React, {useEffect} from "react";
import DialogContent from "@material-ui/core/DialogContent";
import {useRecordContext, useTranslate} from "ra-core";
import {useForm, Controller} from 'react-hook-form';
import {AmountInputText} from "../../../../ui/fields/AmountInputText";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import {LinearProgress} from "@mui/material";
import {useMutation} from "react-query";
import {CustomAutocompleteInput} from "../../../../ui/fields/AutocompleteInput";

export const PaymentFields = props => {
    const record = useRecordContext();
    const translate = useTranslate();
    const {
        formState: {errors},
        setError,
        register,
        setValue,
    } = useForm({mode: "all"});
    const {
        handleCloseClick,
        onChange,
        closeDialog,
        preSubmitDataManipulation,
        onSubmitPromise
    } = props;
    const {resource} = useListContext();
    const notify = useNotify();
    const action = props.action ?? 'create';
    const editing = action === 'edit';
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(!!props.action);
    const dataProvider = useDataProvider();
    const {mutate, isLoading} = useMutation(
        ['getDetails', record?.id],
        () => dataProvider.getDetails(resource, {id: record.id}),
    );

    useEffect(() => {
        if (editing) {
            mutate(null, {
                onSuccess: (response) => {
                    setData(response.data)
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line
    }, [])

    const setSubmitErrors = (errors) => {
        Object.keys(errors).forEach((error) => {
            setError(error, {type: 'server', message: errors[error]})

        })
    }

    const validatePaymentCreation = (values) => {
        console.log('values', values)
        const errors = {};
        if (!values.amount) {
            errors.amount = `resources.errors.missing`;
        }
        if (!values.description) {
            errors.description = `resources.errors.missing`;
        }
        if (!values.long_description) {
            errors.long_description = `resources.errors.missing`;
        }
        console.log(errors);
        return errors
    };

    const submit = (values) => {
        return new Promise(resolve => {
            let data = values;

            onSubmitPromise(data).then(response => {
                resolve(true);
                closeDialog();
                setLoading(false);
                notify(`resources.${resource}.notifications.${action}.success`, {type: 'success'})
                onChange();
            }).catch(error => {
                const fieldErrorRenderable = Object.keys(error.body.data.description).some(r => Object.keys(data).indexOf(r) >= 0);
                const renderableError = error.body?.data.description !== null && typeof error.body?.data.description === 'object' && fieldErrorRenderable;
                if (renderableError) {
                    const errors = error.body.data.description;
                    setSubmitErrors(errors);
                } else {
                    notify(`resources.${resource}.notifications.${action}.failure`, {type: 'error'})
                }

                setLoading(false);
            });
        });
    }

    const PaymentToolbar = props => (
        <Toolbar {...props} >
            <SaveButton
                label={editing ? `ra.action.save` : `ra.action.create`}
                redirect={false}
                submitOnEnter={false}
                variant="flat"
                
            />
            <Button
                onClick={closeDialog}
                variant="flat"
                label={`ra.action.cancel`}
            >
                <IconCancel/>
            </Button>

        </Toolbar>
    );


    return (
        <>
            {!loading && <DialogContent>
                    <SimpleForm onSubmit={submit} validate={validatePaymentCreation} toolbar={<PaymentToolbar/>}>
                    <Controller
                        name={"amount"}
                        render={({field: {onChange, value}}) => (
                            <AmountInputText
                                onChange={(event, item) => {
                                    setValue('amount', item.value * 100)
                                }}
                                name={"amount"}
                                source="amount"
                                validate={required()}
                                value={(record?.amount/100) || ''}
                                disabled={editing || loading }
                                label={translate('resources.payments.fields.amount')}
                                register={register}

                            />
                            )
                        }
                    />

                    <TextInput
                        multiline
                        source="description"
                        fullWidth
                        defaultValue={record?.description}
                        disabled={editing || loading }
                        label={translate('resources.payments.fields.description')}
                        {...register('description', {required: `resources.payments.errors.missing`})}
                    />


                    <TextInput
                        source="long_description"
                        fullWidth
                        multiline
                        defaultValue={record?.longDescription}
                        label={translate('resources.payments.fields.longDescription')}
                        disabled={loading}
                        {...register('long_description')}
                    />


                    <TextInput
                        source="notes"
                        fullWidth
                        defaultValue={record?.notes}
                        label={translate('resources.payments.fields.notes')}
                        disabled={loading}
                        {...register('notes')}
                    />


                </SimpleForm>
            </DialogContent>
            }
            {loading && <DialogContent>
                <LinearProgress/>
            </DialogContent>}
        </>
    );
}

